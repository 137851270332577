import { HTTP } from '../../axios/axios'
let data

async function getAll (lang) {
  try {
    const res = await HTTP.get('/areasOfInterest', { headers: { 'accept-language': lang } })
    data = res.data
    return data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (area, lang) {
  area.lang = lang
  return await HTTP.post('/areasOfInterest', area)
}
async function update (area, id) {
  return await HTTP.patch('/areasOfInterest/' + id, area)
}
async function deleteOne (area) {
  return await HTTP.delete('/areasOfInterest/' + area._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/areasOfInterest/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
